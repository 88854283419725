import React, { useEffect, useState } from 'react'
import './Home.scss'
import NavBar from '../NavBar/NavBar'
import Topics from '../Topics/Topics'
import ReactGA from 'react-ga4'
import Voting from '../Voting/Voting'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react'
import { eventTrack } from '../../analytics/analytics-utils'
import { useAuthHelper } from '../../utils/authHelper'

function AuthAndLoadMain() {
    useAuthHelper()
    return <></>
}

export default function Home() {
    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
    }, [])
    const [iAmFord, setIAmFord] = useState(false)

    return (
        <div>
            <header>
                <a className={'skip-link'} href={'#main-content'}>
                    Skip to main content
                </a>
            </header>
            <main className="grid">
                <AuthenticatedTemplate>
                    <NavBar />
                    <Voting />
                    <Topics />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NavBar />
                    <div className={'ssomsg'}>
                        <p className="tag-line">
                            You must be signed in to access this room.
                        </p>
                        <br />
                        <button
                            data-testid="sso-button"
                            onClick={() => {
                                eventTrack('Login page', 'Has SSO', 'Button')
                                setIAmFord(true)
                            }}
                            className="button child sign-in-button"
                        >
                            Sign in with Ford SSO
                        </button>
                    </div>
                    {iAmFord && <AuthAndLoadMain></AuthAndLoadMain>}
                </UnauthenticatedTemplate>
            </main>
        </div>
    )
}
